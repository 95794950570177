import React, { useEffect, useState } from 'react';
import ProductList from './ProductList';
import './ProductPageV2.css';
import UserService from '../services/Register';

const ProductPageV2 = () => {
  const [products, setProductsList] = useState([]);
  const [amount, setAmount] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (amount <= 0) {
      alert("Please enter a valid amount");
      return;
    }
    handleInputOrder();
  };

  const handleInputOrder = async () => {
    const data = {
      amount: amount * 100,
      product: 137
    };

    try {
      const response = await UserService.getOrder(data);
      if (response.status === 200) {
        const options = {
          key: process.env.razorpay_key,
          amount: data.amount,
          currency: "INR",
          order_id: response.data.id,
          name: "Samply",
          handler: function (response) {
            alert("Payment Successful!");
          },
          theme: {
            color: "#ffcc00"
          }
        };
        const razorpay = new window.Razorpay(options);
        razorpay.open();
      }
    } catch (error) {
      console.error("Order creation failed:", error);
      alert("Order creation failed. Please try again.");
    }
  };

  return (
    <div className="product-page">
      <header className="page-header">
        <h1>Product Combo Payment</h1>
        <p>Choose your combo package and make a secure payment</p>
      </header>
      <main className="page-main">
        <div className="payment-card">
          <h2>Quick Payment</h2>
          <form onSubmit={handleSubmit} className="payment-form">
            <div className="form-group">
              <label htmlFor="amount" className="payment-label">Enter Amount (INR)</label>
              <input
                type="number"
                id="amount"
                name="amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className="payment-input"
                placeholder="Enter amount"
              />
            </div>
            <button type="submit" className="payment-button">Generate Order</button>
          </form>
        </div>
      </main>
      <footer className="page-footer">
        <p>Samply &copy; 2024. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default ProductPageV2;